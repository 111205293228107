import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalApplicationSettings } from 'app/app.settings';
import { AuthService } from 'app/auth/auth.service';
import { LoadingIndicatorService } from 'app/core/loading-indicator.service';
import * as StackTrace from 'stacktrace-js';
import { environment } from './../../environments/environment';
import { LoggingService } from './logging.service';
import { RoutingStateService } from './services/routingstate.service';
import { UrlService } from './services/url.service';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    private readonly injector: Injector,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly loggingService: LoggingService,
    private readonly routingStateService: RoutingStateService,
    private readonly urlService: UrlService,
    private readonly authService: AuthService
  ) {}

  handleError(error: any) {
    if (this.settings.maintenanceMode || !navigator.onLine || error?.status === 0) {
      return;
    }

    // There is an exception, the failure of loading chunks means we need to reload the application
    if (!!error?.message && error.message.indexOf('Loading chunk') !== -1) {
      this.urlService.setLocationHref(this.routingStateService.lastNavigationStart.url);
      return;
    }

    // Either a HttpResponse or a rejected promise with a http response can have a 401 in there, we should log out.
    if (error?.status === 401 || error?.rejection?.status === 401) {
      this.authService.startLogout();
      return;
    }

    const message: string = error.message ? error.message : Object.keys(error).join('\n');
    const url = this.router.url;

    let promise = Promise.resolve<StackTrace.StackFrame[]>([]);
    if (error instanceof Error) {
      promise = StackTrace.fromError(error);
    }

    promise.then((stackframes: any[]) => {
      const stackTrace = stackframes
        .splice(0, 20)
        .map((sf) => sf.toString())
        .join('\n');

      this.log(this, message, url, stackTrace, error);

      this.loadingIndicatorService.setPageLoadFailed();

      if (environment.production) {
        this.router.navigate(['/error'], { preserveFragment: false, skipLocationChange: false, replaceUrl: true });
      }
    });
  }

  private log(sender: any, message: string, url: string | null, stackTrace: string, error: any) {
    if (this.logErrorAsWarning(message)) {
      this.loggingService.warnWithStackTrace(sender, message, url, stackTrace, error);
    } else {
      this.loggingService.error(sender, message, url, stackTrace, error);
    }
  }

  private logErrorAsWarning(errorMessage: string): boolean {
    // There is an exception coming from a promise, log as warning to keep the error log clean
    return errorMessage.indexOf('Uncaught (in promise)') !== -1;
  }

  private get router(): Router {
    return this.injector.get(Router);
  }

  private get settings(): GlobalApplicationSettings {
    return this.injector.get<GlobalApplicationSettings>(GlobalApplicationSettings);
  }
}
