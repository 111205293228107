import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalApplicationSettings } from 'app/app.settings';
import { LoadingIndicatorService } from 'app/core/loading-indicator.service';
import { LoggingService } from 'app/core/logging.service';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(
    private readonly injector: Injector,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly loggingService: LoggingService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.settings.handleHttpExceptions || this.settings.maintenanceMode) {
      return next.handle(req);
    }
    return next.handle(req).pipe(tap(this.handleResponse), catchError(this.handleError));
  }

  private handleError = (error: HttpEvent<any>) => {
    if (error instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
        return throwError(error);
      }
      this.logError(
        error.status,
        `${error.status}: ${error.statusText}| message: ${error.message} | ${error.error} |url: ${error.url}`,
        error.url
      );
    }
    return throwError(error);
  }

  private handleResponse = (response: HttpEvent<any>) => {
    if (response instanceof HttpResponse) {
      if (response.status > 300) {
        this.logError(response.status, `${response.status}: ${response.statusText}| url: ${response.url}`, response.url);
      }
    }
  }

  private logError(statusCode: number, message: string, url: string | null) {
    // The following statuses are ignored for logging.
    // 401 Unauthorized -- We redirect the user to login again.
    // 429 Too Many Requests -- Which is logged by the backend.
    // 503 Service Unavailable -- We redirect the user to the maintenance page.
    if (![0, 401, 429, 503].includes(statusCode)) {
      if (statusCode < 500) {
        this.loggingService.error(this, message, url);
      }

      this.showError();
    }
  }

  private showError = () => {
    this.loadingIndicatorService.setPageLoadFailed();
    if (environment.production) {
      this.router.navigate(['/error'], { preserveFragment: false, skipLocationChange: false, replaceUrl: true });
    }
  }

  private get router(): Router {
    return this.injector.get(Router);
  }

  private get settings(): GlobalApplicationSettings {
    return this.injector.get<GlobalApplicationSettings>(GlobalApplicationSettings);
  }
}
