import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthLogger, OAuthModule } from 'angular-oauth2-oidc';
import { CoreModule } from 'app/core/core.module';
import { GlobalErrorHandlerService } from 'app/core/global-error-handler.service';
import { ErrorHandlingInterceptor } from 'app/core/requestinterceptors/error-handling.interceptor';
import { MaintenanceModeInterceptor } from 'app/core/requestinterceptors/maintenanceinterceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgentInterceptor } from './auth/agent.interceptor';
import { AuthLogging } from './auth/auth.logging';
import { LoggingInterceptor } from './core/requestinterceptors/logging.interceptor';
import { TrackingInterceptor } from './core/requestinterceptors/tracking.interceptor';
import { CacheInterceptor } from './shared/cache/cache.interceptor';
import * as features from './shared/models/features';
import { SharedModule } from './shared/shared.module';
import { StartupService } from './startup.service';

registerLocaleData(localeNl);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule.withConfig({
      disableAnimations:
        !('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent))
    }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
    CoreModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['/api'],
        sendAccessToken: true
      }
    }),
    AppRoutingModule
  ],
  bootstrap: [AppComponent],
  providers: [
    StartupService,
    { provide: OAuthLogger, useClass: AuthLogging },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceModeInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TrackingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AgentInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [
        StartupService,
        // ATTENTION! The order of the feature toggles is very important.
        features.BlockPrivacySettingsFeature,
        features.LateDeliveryNotificationFeature,
        features.SapMigrationFeature,
        features.VeiligOnlineProductsFeature,
        features.Settings2MagentaFeature,
        features.Invoices2MagentaFeature,
        features.CmsFeature,
        features.Installment2MagentaFeature,
        features.InitialOrderRedirectFeature
      ],
      multi: true
    }
  ]
})
export class AppModule {}

export function startupServiceFactory(
  startupService: StartupService,
  // ATTENTION! The order of the feature toggles is very important.
  blockPrivacySettingsFeature: features.BlockPrivacySettingsFeature,
  lateDeliveryNotificationFeature: features.LateDeliveryNotificationFeature,
  sapMigrationFeature: features.SapMigrationFeature,
  veiligOnlineProductsFeature: features.VeiligOnlineProductsFeature,
  settings2MagentaFeature: features.Settings2MagentaFeature,
  invoices2MagentaFeature: features.Invoices2MagentaFeature,
  cmsFeature: features.CmsFeature,
  installment2MagentaFeature: features.Installment2MagentaFeature,
  initialOrderRedirectFeature: features.InitialOrderRedirectFeature
) {
  return () =>
    // ATTENTION! The order of the feature toggles is very important.
    startupService.load(
      blockPrivacySettingsFeature,
      lateDeliveryNotificationFeature,
      sapMigrationFeature,
      veiligOnlineProductsFeature,
      settings2MagentaFeature,
      invoices2MagentaFeature,
      cmsFeature,
      installment2MagentaFeature,
      initialOrderRedirectFeature
    );
}
